<template>
  <div id="assigned-subject" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <div class="columns" v-else>
      <div class="column is-6 is-offset-3">
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <b-field label="Educator">
            <b-select
              v-model="assignedSubject.educator.id"
              name="educator"
              placeholder="Select a option"
              expanded
            >
              <option
                v-for="educator in educators"
                :key="educator.id"
                :value="educator.id"
              >
                {{ educator.name }}
              </option>
            </b-select>
          </b-field>
          <template v-if="assignedSubject.id">
            <SdSelect
              rules="required"
              label="School Class"
              v-model="assignedSubject.schoolClass.id"
              :disabled="true"
            >
              <option
                v-for="schoolClass in schoolClasses"
                :key="schoolClass.id"
                :value="schoolClass.id"
              >
                {{ schoolClass.name }}
              </option>
            </SdSelect>
            <SdSelect
              rules="required"
              label="Subject"
              v-model="assignedSubject.subject.id"
            >
              <option
                v-for="subject in subjects"
                :key="subject.id"
                :value="subject.id"
              >
                {{ subject.name }}
              </option>
            </SdSelect>
          </template>
          <template v-else>
            <b-field label="School Class">
              <b-select
                v-model="selectedSchoolClasses"
                name="school class"
                placeholder="Select a option"
                expanded
                multiple
                native-size="5"
              >
                <option
                  v-for="schoolClass in schoolClasses"
                  :key="schoolClass.id"
                  :value="schoolClass.id"
                >
                  {{ schoolClass.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Subjects">
              <b-select
                v-model="selectedSubjects"
                name="subject"
                placeholder="Select a option"
                expanded
                multiple
                native-size="5"
              >
                <option
                  v-for="subject in subjects"
                  :key="subject.id"
                  :value="subject.id"
                >
                  {{ subject.name }}
                </option>
              </b-select>
            </b-field>
          </template>
          <button
            type="submit"
            class="button btn-120 is-primary is-pulled-right"
            @click="passes(submit)"
          >
            Submit
          </button>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'
import EDUCATORS from '../../../graphql/educator/Educators.gql'
import SUBJECTS from '../../../graphql/subject/Subjects.gql'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'
import { fetchTerm } from '@/assets/js/app_info.js'

export default {
  name: 'assigned-subject',
  data() {
    return {
      schoolId: null,
      termId: null,
      pageTitle: 'Assigned Subject',
      skipQuery: true,
      educators: [],
      subjects: [],
      subjectIds: [],
      schoolClasses: [],
      schoolClassIds: [],
      selectedSchoolClasses: [],
      selectedSubjects: [],
      assignedSubject: {
        id: null,
        educator: {
          id: null,
          name: null,
        },
        subject: {
          id: null,
          name: null,
        },
        schoolClass: {
          id: null,
          name: null,
        },
      },
    }
  },
  apollo: {
    assignedSubject: {
      query: gql`
        query AssignedSubjectQuery($id: ID!) {
          assignedSubject(id: $id) {
            id
            educator {
              id
              name
            }
            subject {
              id
              name
            }
            schoolClass {
              id
              name
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
    schoolClasses: {
      query: SCHOOL_CLASSES,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
    subjects: {
      query: SUBJECTS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
    educators: {
      query: EDUCATORS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  watch: {
    assignedSubject(value) {
      if (!value.educator) {
        value.educator = {}
      }
      this.$store.commit('setSubMenus', [
        {
          name: 'Assigned Subjects Info',
          route: `/school/${this.$route.params.school_id}/assigned_subject_info/${value.schoolClass.id}`,
        },
        {
          name: 'Assigned Subjects',
          route: `/school/${this.$route.params.school_id}/assigned_subjects`,
        },
      ])
    },
    selectedSchoolClasses(data) {
      this.schoolClassIds = data
    },
    selectedSubjects(data) {
      this.subjectIds = data
    },
  },
  methods: {
    submit() {
      if (!this.assignedSubject.educator.id) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Select an educator',
          type: 'is-danger',
        })
        return
      } else {
        const educatorId = parseInt(this.assignedSubject.educator.id)

        if (this.$route.params.id === 'new') {
          const schoolClassIds = JSON.parse(JSON.stringify(this.schoolClassIds))
          const subjectIds = JSON.parse(JSON.stringify(this.subjectIds))
          // Add
          schoolClassIds.forEach((schoolClassId) => {
            subjectIds.forEach((subjectId) => {
              this.$apollo
                .mutate({
                  mutation: gql`
                    mutation CreateAssignedSubjectQuery(
                      $educator_id: Int!
                      $subject_id: Int!
                      $school_class_id: Int!
                      $term_id: Int!
                    ) {
                      createAssignedSubject(
                        input: {
                          educatorId: $educator_id
                          subjectId: $subject_id
                          schoolClassId: $school_class_id
                          termId: $term_id
                        }
                      ) {
                        assignedSubject {
                          id
                        }
                        errors
                      }
                    }
                  `,
                  // Parameters
                  variables: {
                    educator_id: educatorId,
                    subject_id: parseInt(subjectId),
                    school_class_id: parseInt(schoolClassId),
                    term_id: parseInt(this.termId),
                  },
                })
                .then((response) => {
                  crudNotification(
                    response.data.createAssignedSubject.errors,
                    'Successfully created.'
                  )
                })
                .catch((error) => {
                  console.error(error)
                })
            })
          })

          this.$router.push(`/school/${this.schoolId}/assigned_subjects`)
        } else {
          this.$apollo
            .mutate({
              mutation: gql`
                mutation UpdateAssignedSubjectQuery(
                  $id: Int!
                  $educatorId: Int!
                  $subjectId: Int!
                  $schoolClassId: Int!
                ) {
                  updateAssignedSubject(
                    input: {
                      id: $id
                      educatorId: $educatorId
                      subjectId: $subjectId
                      schoolClassId: $schoolClassId
                    }
                  ) {
                    assignedSubject {
                      id
                    }
                    errors
                  }
                }
              `,
              variables: {
                id: parseInt(this.assignedSubject.id),
                educatorId: educatorId,
                subjectId: parseInt(this.assignedSubject.subject.id),
                schoolClassId: parseInt(this.assignedSubject.schoolClass.id),
              },
            })
            .then((response) => {
              crudNotification(
                response.data.updateAssignedSubject.errors,
                'Successfully updated.'
              ).then(() => {
                this.$router.push(
                  `/school/${this.schoolId}/assigned_subject_info/${this.assignedSubject.schoolClass.id}`
                )
              })
            })
            .catch((error) => {
              console.error(error)
            })
        }
      }
    },
  },
  components: {
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Assigned Subjects',
        route: `/school/${this.schoolId}/assigned_subjects`,
      },
    ])
    if (this.$route.params.id !== 'new') this.skipQuery = false

    fetchTerm().then((term) => {
      this.termId = term.id
    })
  },
}
</script>

<style lang="scss" scoped></style>
style
